import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Paginate
  },
  data: function() {
    return {
      busy: false,
      perPage: 12,
      sortBy: 'created_at',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      fields: [
        {
          key: "lot_no",
          label: "LOT No.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "code",
          label: "รหัสม้วน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "brand",
          label: "ยี่ห้อ.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "coil",
          label: "COIL NO.",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "size",
          label: "SIZE",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "color",
          label: "COLOR",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "net_weight",
          label: "NET WEIGHT",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "kg_m",
          label: "Kg/m",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "length",
          label: "LENGTH",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 text-right font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "warehouse",
          label: "คลัง",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "row",
          label: "แถว",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
      ],
      items: [
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  mounted() {
    this.onInitData(1)
  },
  methods: {
    async onInitData(currentPage) {
      try {
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const result = await this.$axios.get(
          this.$host + "/api/v1/report/stock/remaining?page=" + currentPage,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          }
        )

        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        if (result) {
          this.currentData = result.data
          this.items = result.data.data
        } else {          
          this.items = []
        }
        
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      } 
    },
    async Export() {
      try {
        this.loading = true
        await this.$axios.post(`${Vue.prototype.$host}/api/v1/export/report/stock/remaining`, {}, {
          responseType: 'blob'
        }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'สต็อกคงเหลือ ' + new Date().toLocaleDateString("th-TH", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }) + '.xlsx')
          document.body.appendChild(link)
          link.click()
          this.loading = false
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}
